import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import "./custom.css"
import { SignForm } from "../components/SignForm"
import { Grid } from "semantic-ui-react"
import { SignatureList } from "../components/SignatureList"
import { TopHeader } from "../components/TopHeader"
import facebookIcon from "../icons/facebook.png"
import twitterIcon from "../icons/twitter.png"
import instagramIcon from "../icons/instagram.png"

class Index extends React.Component {
  constructor() {
    super()
    this.allPosts = this.allPosts.bind(this)
    this.declaration = this.declaration.bind(this)
    this.state = {
      showMenu: false,
      shouldUpdateSignatures: false,
    }
    this.setShouldUpdateSignatures = this.setShouldUpdateSignatures.bind(this)
  }

  setShouldUpdateSignatures(shouldUpdate) {
    this.setState({ shouldUpdateSignatures: shouldUpdate })
  }

  allPosts() {
    const { data } = this.props
    return data.allMarkdownRemark.edges
  }

  declaration() {
    return this.allPosts().find(
      post => post.node.frontmatter.description === "declaration"
    ).node.html
  }

  blogPosts() {
    return this.allPosts().filter(
      post => post.node.frontmatter.description !== "declaration"
    )
  }

  render() {
    const siteTitle = this.props.data.site.siteMetadata.title

    return (
      <>
        <TopHeader />
        <Layout location={this.props.location} title={siteTitle}>
          <Grid columns={2} container stackable>
            <Grid.Column width={11}>
              <h1 className="main-title">
                Declaration of Responsibility and Interdependence
              </h1>
              <SEO title="Home" />
              <h1 className="cursive">
                We the Peoples{" "}
                <span className="header-small">
                  of the Planet Earth declare that all life is One.
                </span>
              </h1>
              <section
                dangerouslySetInnerHTML={{ __html: this.declaration() }}
              ></section>
            </Grid.Column>
            <Grid.Column width={5}>
              <a name="sign"></a>
              <div className="social-icons">
                <a href="https://www.facebook.com/thedeclarationofresponsibility/">
                  <img alt="facebook icon" src={facebookIcon} />
                </a>
                <a href="https://twitter.com/signthedeclara1">
                  <img alt="twitter icon" src={twitterIcon} />
                </a>
                <a href="https://www.instagram.com/signthedeclaration/">
                  <img alt="instagram icon" src={instagramIcon} />
                </a>
              </div>
              {/* <SignForm
                setShouldUpdateSignatures={this.setShouldUpdateSignatures}
              /> */}
              <SignatureList
                shouldUpdateSignatures={this.state.shouldUpdateSignatures}
                setShouldUpdateSignatures={this.setShouldUpdateSignatures}
              />
            </Grid.Column>
          </Grid>
        </Layout>
      </>
    )
  }
}

export default Index

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }) {
      edges {
        node {
          html
          excerpt
          fields {
            slug
          }
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            title
            description
          }
        }
      }
    }
  }
`
